import React from "react";
import Seo from "../../components/seo";
import Layout from "../../Layout";
import Breadcrumbs from "../../components/Breadcrumbs";
import Image1 from "./images/spotato1.jpg";
import Image2 from "./images/spotato2.jpg";
import Image3 from "./images/spotato3.jpg";
import Image4 from "./images/spotato4.jpg";
import Image5 from "./images/spotato5.jpg";
import Image6 from "./images/spotato6.jpg";
import FarmImage1 from "./images/farm1.jpg";
import FarmImage2 from "./images/farm2.jpg";

const images = [Image1, Image2, Image3, Image4, Image5, Image6];

const sweetPotatoBenefits = [
  {
    text: "Rich in Beta-Carotene",
    details: "Supports healthy vision, skin, and immune function.",
  },
  {
    text: "Packed with Antioxidants",
    details:
      "Helps fight oxidative stress and protect against chronic diseases.",
  },
  {
    text: "Promotes Digestive Health",
    details: "High in fiber, which aids digestion and supports gut health.",
  },
  {
    text: "Stabilizes Blood Sugar",
    details: "Lower glycemic index helps maintain stable blood sugar levels.",
  },
  {
    text: "Supports Heart Health",
    details:
      "Rich in potassium, which helps regulate blood pressure and supports heart function.",
  },
];

const SweetPotatoPage = () => (
  <Layout>
    <Seo title="Yellow Sweet Potatoes" />
    <Breadcrumbs title="Yellow Sweet Potatoes" />
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8">
          <style>
            {`
              .fixed-size { width: 200px; height: 200px; object-fit: cover; margin: 3px; }
              .narrow-paragraph { max-width: 700px; margin: 0 auto; }
              .custom-table { width: 100%; margin-top: 20px; border-collapse: collapse; }
              .custom-table th, .custom-table td { border: 1px solid #ddd; padding: 8px; }
              .custom-table th { background-color: #f2f2f2; text-align: left; }
              .custom-table .green-header { background-color: #d4edda; }
              .margin-bottom { margin-bottom: 50px; }
              .farm-description { display: flex; align-items: center; margin-bottom: 15px; }
              .farm-description img { margin-right: 15px; width: 200px; height: 200px; object-fit: cover; }
              
              @media (max-width: 768px) {
                .fixed-size { width: 100%; height: auto; }
                .narrow-paragraph { padding: 0 15px; }
                .custom-table, .custom-table th, .custom-table td { font-size: 14px; }
                .farm-description img { width: 100%; height: auto; margin-right: 10px; }
                .margin-bottom { margin-bottom: 30px; }
              }
            `}
          </style>
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="row">
                {images.slice(0, 3).map((image, index) => (
                  <div className="col-6 col-md-4" key={index}>
                    <img
                      src={image}
                      alt={`Sweet potato field ${index + 1}`}
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-2">
                {images.slice(3).map((image, index) => (
                  <div className="col-6 col-md-4" key={index + 3}>
                    <img
                      src={image}
                      alt={`Sweet potato field ${index + 4}`}
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-3 narrow-paragraph">
                <div className="col-12">
                  <p>
                    Yellow sweet potatoes are a nutritious and versatile root
                    vegetable, known for their smooth golden skin and vibrant
                    yellow flesh. They are often confused with yams, but they
                    are distinct in both texture and taste. With a creamy
                    texture and a slightly sweet flavor, yellow sweet potatoes
                    are a great addition to both savory and sweet dishes. When
                    grown organically, they offer the highest quality nutrition
                    and flavor, making them a must-have for any health-conscious
                    eater.
                  </p>
                  <p>
                    Choosing organic yellow sweet potatoes ensures that you are
                    getting the best quality without the harmful chemicals and
                    pesticides used in conventional farming. Organic farming
                    methods prioritize sustainability, environmental health, and
                    soil quality, which means you are not only eating healthier
                    but also supporting a system that preserves our planet's
                    resources.
                  </p>
                </div>
              </div>
              <h3>
                <strong>Nutrition Table</strong>
              </h3>
              <table className="custom-table margin-bottom">
                <thead>
                  <tr>
                    <th className="green-header">Nutrient</th>
                    <th className="green-header">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Calories</td>
                    <td>103 kcal</td>
                  </tr>
                  <tr>
                    <td>Protein</td>
                    <td>2.3 g</td>
                  </tr>
                  <tr>
                    <td>Fat</td>
                    <td>0.2 g</td>
                  </tr>
                  <tr>
                    <td>Carbohydrates</td>
                    <td>23.6 g</td>
                  </tr>
                  <tr>
                    <td>Fiber</td>
                    <td>3.8 g</td>
                  </tr>
                  <tr>
                    <td>Sugars</td>
                    <td>7.4 g</td>
                  </tr>
                  <tr>
                    <td>Vitamin A</td>
                    <td>384% of DV</td>
                  </tr>
                  <tr>
                    <td>Vitamin C</td>
                    <td>4% of DV</td>
                  </tr>
                  <tr>
                    <td>Vitamin B6</td>
                    <td>15% of DV</td>
                  </tr>
                  <tr>
                    <td>Potassium</td>
                    <td>438 mg</td>
                  </tr>
                  <tr>
                    <td>Calcium</td>
                    <td>39 mg</td>
                  </tr>
                  <tr>
                    <td>Iron</td>
                    <td>0.7 mg</td>
                  </tr>
                </tbody>
              </table>
              <h3>
                <strong>Our Organic Farms</strong>
              </h3>
              <div className="farm-description">
                <img
                  src={FarmImage1}
                  alt="Organic Farm 1"
                  className="img-thumbnail"
                />
                <p>Farm at Nuwara Eliya Control Union Certified</p>
              </div>
              <div className="farm-description">
                <img
                  src={FarmImage2}
                  alt="Organic Farm 2"
                  className="img-thumbnail"
                />
                <p>Farm at Chilaw PGS Certified</p>
              </div>
            </div>
            <div className="col-12 col-md-4 ml-auto">
              <h2>Health Benefits of Yellow Sweet Potatoes</h2>
              <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                {sweetPotatoBenefits.map((benefit, index) => (
                  <li key={index}>
                    <span style={{ color: "green" }}>✅</span>
                    <span style={{ color: "black" }}>
                      <strong>{benefit.text}:</strong> {benefit.details}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default SweetPotatoPage;
